import { Button, useDisclosure } from '@chakra-ui/react';
import AlertWindow from './components/AlertWindow';
import { FraudFlagType } from '../../api/sms-backoffice-api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { fraudFlagsApi } from '../../api/api';
import { fetchFraudData } from '../../store/fraudDataSlice';

interface DeleteButtonGroupProps {
  selectedIds: string[];
  detectionType: FraudFlagType;
  clearSelection: () => void;
}

const DeleteButtonGroup = ({
  selectedIds,
  detectionType,
  clearSelection,
}: DeleteButtonGroupProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch<AppDispatch>();
  const pageSize = useSelector((state: RootState) => state.fraudData.pageSize);

  const handleConfirm = async () => {
    try {
      for (const id of selectedIds) {
        await fraudFlagsApi.removeFraudFlag(detectionType, id);
      }

      dispatch(fetchFraudData({ fraudFlagType: detectionType, page: 1, pageSize }));

      clearSelection();

      onClose();
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="red" isDisabled={selectedIds.length === 0}>
        Delete
      </Button>
      <AlertWindow isOpen={isOpen} onClose={onClose} onConfirm={handleConfirm} />
    </>
  );
};

export default DeleteButtonGroup;
