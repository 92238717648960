import { configureStore } from '@reduxjs/toolkit';
import fraudDataReducer from './fraudDataSlice';
import detectionTypeReducer from './detectionTypeSlice';

export const store = configureStore({
  reducer: {
    fraudData: fraudDataReducer,
    detectionType: detectionTypeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
