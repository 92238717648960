import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text, Box, HStack, Stack } from '@chakra-ui/react';
import { authApi, fraudFlagsApi } from '../../api/api';
import InputBar from '../InputBar';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      fraudFlagsApi
        .listFraudFlags('FULL_NAME')
        .then(() => {
          navigate('/list');
        })
        .catch(() => {
          navigate('/');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('username');
          // console.log('Invalid token, please login again');
        });
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      const response = await authApi.login({ username, plainPassword: password });
      const { token } = response.data;

      if (token) {
        localStorage.setItem('accessToken', token);
        localStorage.setItem('username', username);
        navigate('/list');
      } else {
        throw new Error('No token received');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Username or password is incorrect.');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Box p={4} onKeyDown={handleKeyDown}>
      {error && <Text color="red">{error}</Text>}
      <HStack spacing={4}>
        <div>
          <Stack spacing={4}>
            <InputBar
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              inputName="Username"
              inputType="text"
            />
            <InputBar
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputName="Password"
              inputType="password"
            />
          </Stack>
        </div>
        <div>
          <Button colorScheme="blue" height="96px" width="100px" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </HStack>
    </Box>
  );
};

export default Login;
