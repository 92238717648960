import { useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import DetectionTypeSwitch from '../../components/DetectionTypeSwitch';
import FraudFlagTable from '../../components/FraudFlagTable';
import { useNavigate } from 'react-router-dom';
import { FraudFlagDto } from '../../api/sms-backoffice-api';
import FraudInfoWindow from '../../components/AddButtonGroup/components/FraudInfoWindow';
import { useDispatch } from 'react-redux';
import { setDetectionType } from '../../store/detectionTypeSlice';

const List = () => {
  const [currentItem] = useState<FraudFlagDto | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const username = localStorage.getItem('username') || 'Unknown';

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    navigate('/');
  };

  const handleSave = (newDetectionType: string) => {
    dispatch(setDetectionType(newDetectionType.replace(/\s+/g, '_')));
    setIsModalOpen(false);
  };

  return (
    <Box textAlign={'left'}>
      <Flex direction="row" justifyContent="flex-end" alignItems="center" gap="24px">
        <Text fontSize="lg">User: {username}</Text>
        <Button colorScheme="teal" size="sm" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Flex>

      <Text fontSize="lg">Detection Type</Text>
      <DetectionTypeSwitch />

      <FraudFlagTable />

      {currentItem && (
        <FraudInfoWindow
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          item={currentItem}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default List;
