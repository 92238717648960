/* tslint:disable */
/* eslint-disable */
/**
 * SMS Backoffice Api Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthTokenDto
 */
export interface AuthTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenDto
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface FraudDetectionResultDto
 */
export interface FraudDetectionResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof FraudDetectionResultDto
     */
    'fraudResult'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FraudDetectionResultDto
     */
    'fraudSummary'?: string | null;
}
/**
 * 
 * @export
 * @interface FraudFlagDto
 */
export interface FraudFlagDto {
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {FraudFlagType}
     * @memberof FraudFlagDto
     */
    'type'?: FraudFlagType;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'value2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudFlagDto
     */
    'updatedOn'?: string | null;
}


/**
 * 
 * @export
 * @interface FraudFlagPaginationResponseDto
 */
export interface FraudFlagPaginationResponseDto {
    /**
     * 
     * @type {Array<FraudFlagDto>}
     * @memberof FraudFlagPaginationResponseDto
     */
    'items'?: Array<FraudFlagDto>;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof FraudFlagPaginationResponseDto
     */
    'pageCount'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FraudFlagType = {
    Email: 'EMAIL',
    FullName: 'FULL_NAME',
    BankDetails: 'BANK_DETAILS',
    Postcode: 'POSTCODE',
    Mobile: 'MOBILE',
    Srn: 'SRN'
} as const;

export type FraudFlagType = typeof FraudFlagType[keyof typeof FraudFlagType];


/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'plainPassword': string;
}
/**
 * 
 * @export
 * @interface NaturalPersonDto
 */
export interface NaturalPersonDto {
    /**
     * 
     * @type {string}
     * @memberof NaturalPersonDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof NaturalPersonDto
     */
    'fullName'?: string;
}
/**
 * 
 * @export
 * @interface SaleDetailsDto
 */
export interface SaleDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'contactMobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'shareholderPostcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'bankBsbNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetailsDto
     */
    'bankAccountNumber'?: string;
    /**
     * 
     * @type {Array<NaturalPersonDto>}
     * @memberof SaleDetailsDto
     */
    'naturalPersons'?: Array<NaturalPersonDto>;
    /**
     * 
     * @type {Array<ShareParcelDto>}
     * @memberof SaleDetailsDto
     */
    'shareParcels'?: Array<ShareParcelDto>;
}
/**
 * 
 * @export
 * @interface ShareParcelDto
 */
export interface ShareParcelDto {
    /**
     * 
     * @type {string}
     * @memberof ShareParcelDto
     */
    'securityReferenceNumber'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest?: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest?: LoginRequest, options?: any): AxiosPromise<AuthTokenDto> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FraudFlagsApi - axios parameter creator
 * @export
 */
export const FraudFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFraudFlag: async (fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('createFraudFlag', 'fraudFlagType', fraudFlagType)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fraudFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFrauds: async (saleDetailsDto?: SaleDetailsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/fraud-flags/detect-frauds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleDetailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFraudFlags: async (fraudFlagType: FraudFlagType, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('listFraudFlags', 'fraudFlagType', fraudFlagType)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFraudFlag: async (fraudFlagType: FraudFlagType, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('removeFraudFlag', 'fraudFlagType', fraudFlagType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFraudFlag', 'id', id)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}/{id}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFraudFlag: async (fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fraudFlagType' is not null or undefined
            assertParamExists('updateFraudFlag', 'fraudFlagType', fraudFlagType)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFraudFlag', 'id', id)
            const localVarPath = `/v1/fraud-flags/{fraudFlagType}/{id}`
                .replace(`{${"fraudFlagType"}}`, encodeURIComponent(String(fraudFlagType)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fraudFlagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FraudFlagsApi - functional programming interface
 * @export
 */
export const FraudFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FraudFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFraudFlag(fraudFlagType, fraudFlagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.createFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudDetectionResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectFrauds(saleDetailsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.detectFrauds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFraudFlags(fraudFlagType: FraudFlagType, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagPaginationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFraudFlags(fraudFlagType, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.listFraudFlags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFraudFlag(fraudFlagType, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.removeFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FraudFlagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFraudFlag(fraudFlagType, id, fraudFlagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FraudFlagsApi.updateFraudFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FraudFlagsApi - factory interface
 * @export
 */
export const FraudFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FraudFlagsApiFp(configuration)
    return {
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: any): AxiosPromise<FraudFlagDto> {
            return localVarFp.createFraudFlag(fraudFlagType, fraudFlagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaleDetailsDto} [saleDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: any): AxiosPromise<FraudDetectionResultDto> {
            return localVarFp.detectFrauds(saleDetailsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFraudFlags(fraudFlagType: FraudFlagType, page?: number, pageSize?: number, options?: any): AxiosPromise<FraudFlagPaginationResponseDto> {
            return localVarFp.listFraudFlags(fraudFlagType, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeFraudFlag(fraudFlagType, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FraudFlagType} fraudFlagType 
         * @param {string} id 
         * @param {FraudFlagDto} [fraudFlagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: any): AxiosPromise<FraudFlagDto> {
            return localVarFp.updateFraudFlag(fraudFlagType, id, fraudFlagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FraudFlagsApi - object-oriented interface
 * @export
 * @class FraudFlagsApi
 * @extends {BaseAPI}
 */
export class FraudFlagsApi extends BaseAPI {
    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {FraudFlagDto} [fraudFlagDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public createFraudFlag(fraudFlagType: FraudFlagType, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).createFraudFlag(fraudFlagType, fraudFlagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaleDetailsDto} [saleDetailsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public detectFrauds(saleDetailsDto?: SaleDetailsDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).detectFrauds(saleDetailsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public listFraudFlags(fraudFlagType: FraudFlagType, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).listFraudFlags(fraudFlagType, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public removeFraudFlag(fraudFlagType: FraudFlagType, id: string, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).removeFraudFlag(fraudFlagType, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FraudFlagType} fraudFlagType 
     * @param {string} id 
     * @param {FraudFlagDto} [fraudFlagDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudFlagsApi
     */
    public updateFraudFlag(fraudFlagType: FraudFlagType, id: string, fraudFlagDto?: FraudFlagDto, options?: RawAxiosRequestConfig) {
        return FraudFlagsApiFp(this.configuration).updateFraudFlag(fraudFlagType, id, fraudFlagDto, options).then((request) => request(this.axios, this.basePath));
    }
}



