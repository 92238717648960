import React, { useEffect } from 'react';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';

interface Props {
  inputName: string;
  value: string;
  onChange: (value: string) => void;
  isUpdateMode: boolean;
}

const InputBarWithInitialI = ({ inputName, value, onChange, isUpdateMode }: Props) => {
  useEffect(() => {
    if (!isUpdateMode && value === '') {
      onChange('I');
      // console.log('Add mode: Setting initial value to I');
    } else if (isUpdateMode) {
      // console.log('Update mode: Keeping existing value', value);
    }
  }, [isUpdateMode, value, onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!isUpdateMode && !inputValue.startsWith('I')) {
      onChange('I' + inputValue.slice(1));
      // console.log('Add mode: Ensuring input starts with I', inputValue);
    } else {
      onChange(inputValue);
      // console.log('Update mode: Updating input to', inputValue);
    }
  };

  return (
    <div>
      <InputGroup>
        <InputLeftAddon minWidth="160px">{inputName}</InputLeftAddon>
        <Input type="text" value={value} onChange={handleChange} maxLength={13} />
      </InputGroup>
    </div>
  );
};

export default InputBarWithInitialI;
