import { Button, useDisclosure } from '@chakra-ui/react';

import FraudInfoWindow from './components/FraudInfoWindow';

interface AddButtonGroupProps {
  onSave: (newDetectionType: string) => void;
}

const AddButtonGroup = ({ onSave }: AddButtonGroupProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Add</Button>

      <FraudInfoWindow isOpen={isOpen} onClose={onClose} onSave={onSave} />
    </>
  );
};

export default AddButtonGroup;
