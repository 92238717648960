import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDetectionType } from '../../store/detectionTypeSlice';
import { RootState } from '../../store';

const DetectionTypeSwitch = () => {
  const detectionType = useSelector((state: RootState) => state.detectionType.value);
  const dispatch = useDispatch();

  const handleChange = (newType: string) => {
    const formattedType = newType.toUpperCase().replace(/\s+/g, '_');
    dispatch(setDetectionType(formattedType));
  };

  return (
    <RadioGroup onChange={handleChange} value={detectionType.replace(/_/g, ' ')}>
      <Stack direction="row" spacing={4}>
        <Radio value="FULL NAME">Full Name</Radio>
        <Radio value="MOBILE">Mobile</Radio>
        <Radio value="EMAIL">Email</Radio>
        <Radio value="SRN">SRN</Radio>
        <Radio value="BANK DETAILS">Bank Details</Radio>
        <Radio value="POSTCODE">Postcode</Radio>
      </Stack>
    </RadioGroup>
  );
};

export default DetectionTypeSwitch;
