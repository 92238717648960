import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fraudFlagsApi } from '../api/api';
import { FraudFlagType, FraudFlagDto } from '../api/sms-backoffice-api';

interface FraudDataState {
  items: FraudFlagDto[];
  loading: boolean;
  error: string | null;
  page: number;
  pageSize: number;
  pageCount: number;
}

const initialState: FraudDataState = {
  items: [],
  loading: false,
  error: null,
  page: 1,
  pageSize: 10,
  pageCount: 1,
};

export const fetchFraudData = createAsyncThunk(
  'fraudData/fetchFraudData',
  async (
    {
      fraudFlagType,
      page,
      pageSize,
    }: { fraudFlagType: FraudFlagType; page: number; pageSize: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await fraudFlagsApi.listFraudFlags(fraudFlagType, page, pageSize);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error && 'response' in error && error.response) {
        return rejectWithValue(
          (error.response as { data?: any }).data || 'An unknown error occurred',
        );
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

const fraudDataSlice = createSlice({
  name: 'fraudData',
  initialState,
  reducers: {
    resetPage: (state) => {
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFraudData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFraudData.fulfilled, (state, action) => {
      state.items = action.payload.items ?? [];
      state.page = action.payload.page || 1;
      state.pageSize = action.payload.pageSize || 10;
      state.pageCount = action.payload.pageCount || 0;
      state.loading = false;
    });
    builder.addCase(fetchFraudData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { resetPage, setPage } = fraudDataSlice.actions;
export default fraudDataSlice.reducer;
