import './App.css';
import { Routes, Route } from 'react-router-dom';
import Auth from './pages/Auth';
import List from './pages/List';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/list" element={<List />} />
      </Routes>
    </>
  );
}

export default App;
