import axios from 'axios';
import { AuthApi, FraudFlagsApi } from './sms-backoffice-api';
import { backendUrl } from './config/config';


const axiosInstance = axios.create({
  baseURL: backendUrl
});

axiosInstance.interceptors.request.use(config => {

  // const fullUrl = `${config.baseURL}${config.url}`;
  // console.log("Sending Request to:", fullUrl, "With data:", config.data);

  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = token;  
  }
  return config;
}, error => {

  return Promise.reject(error);
});


export const authApi = new AuthApi(undefined, backendUrl, axiosInstance);
export const fraudFlagsApi = new FraudFlagsApi(undefined, backendUrl, axiosInstance);
