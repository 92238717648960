import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DetectionTypeState {
  value: string;
}

const initialState: DetectionTypeState = {
  value: 'FULL_NAME',
};

export const detectionTypeSlice = createSlice({
  name: 'detectionType',
  initialState,
  reducers: {
    setDetectionType: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setDetectionType } = detectionTypeSlice.actions;
export default detectionTypeSlice.reducer;
