import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';

interface Props {
  inputName: string;
  inputType: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputBar = ({ inputName, inputType, value, onChange }: Props) => {
  return (
    <div>
      <InputGroup>
        <InputLeftAddon minWidth="160px">{inputName}</InputLeftAddon>
        <Input type={inputType} value={value} onChange={onChange} />
      </InputGroup>
    </div>
  );
};

export default InputBar;
