import { FraudFlagType } from '../api/sms-backoffice-api';

export const mapToFraudFlagType = (type: string): FraudFlagType | undefined => {
  const map: { [key: string]: FraudFlagType } = {
    EMAIL: FraudFlagType.Email,
    FULL_NAME: FraudFlagType.FullName,
    BANK_DETAILS: FraudFlagType.BankDetails,
    POSTCODE: FraudFlagType.Postcode,
    MOBILE: FraudFlagType.Mobile,
    SRN: FraudFlagType.Srn,
  };
  return map[type.toUpperCase().replace(/\s+/g, '_')];
};
